.containerObjClass {
  position: relative;
}

.Coins-droppable,
.Coins-vertical-droppable {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}
/*.Coins-vertical-droppable {
  margin-top: 10px;
}*/

.Coins-droppable {
  display: flex;
}
.Coins-droppable .coin {
  width: 6px !important;
  height: 30px !important;
  vertical-align: middle;
  margin: auto 0;
}
.Coins-droppable .coin.gold {
  background-image: url("../images/dollar_coin_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.Coins-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Coins-vertical-droppable .coin {
  /*display: table-cell;*/
  display: block;
  width: 30px !important;
  height: 9px !important;
  margin: 0 auto;
}
.Coins-vertical-droppable .coin.gold {
  background-image: url("../images/dollar_coin_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.Coins-vertical-droppable .coin.silver {
  background-image: url("../images/dollar_coin_silver_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.Coins-droppable .coin:nth-child(2) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(3) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(4) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(5) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(6) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(7) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(8) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(9) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(10) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(11) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(12) {
  margin-left: 2px;
}
.Coins-droppable .coin:nth-child(13) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(14) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(15) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(16) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(17) {
  margin-left: 1px;
}
.Coins-droppable .coin:nth-child(18) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(19) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(20) {
  margin-left: -4px;
}
.Coins-droppable .coin:nth-child(21) {
  margin-left: -4px;
}

.Coins-vertical-droppable .coin:nth-child(1) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(2) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(3) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(4) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(5) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(6) {
  margin-top: -1px;
}
.Coins-vertical-droppable .coin:nth-child(7) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(8) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(9) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(10) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(11) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(12) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(13) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(14) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(15) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(16) {
  margin-top: 0px;
}
.Coins-vertical-droppable .coin:nth-child(17) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(18) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(19) {
  margin-top: -7px;
}
.Coins-vertical-droppable .coin:nth-child(20) {
  margin-top: -7px;
}

.Coins-vertical-droppable .Container-Card {
  width: 30px !important;
  height: 30px !important;
  transform: rotateX(70deg);
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -15px;
}

.rent-vertical-droppable .Container-Card {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  bottom: 0px;
  left: 3px;
}

.containerVerticalProductCardDraggable {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  right: -8px;
  transform: rotateY(65deg) rotateZ(-90deg);
  top: 50%;
  margin-top: -15px;
}
.containerProductCardDraggable {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  bottom: -8px;
  transform: rotateX(70deg);
  left: 50%;
  margin-left: -15px;
}
.containerProductCardDraggableImg {
  width: 100%;
  height: 100%;
}
.containerProductCardValue {
  font-size: 16px;
  text-align: center;
  top: 50%;
  position: absolute;
  width: 100%;
  height: 24px;
  margin-top: -12px;
  font-weight: bolder;
  color: black;
}

.dragging {
  cursor: grabbing;
}

.Coins-vertical-droppable .Container-Card-2 {
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  bottom: 2px;
  left: 50%;
  margin-left: -15px;
}

.token-counter-text {
  text-align: center;
  line-height: 2em;
}
.token-counter-btn {
  background-color: #0a2955;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 5px auto;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 900;
  line-height: 2em;
}

.Coins-droppable .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.Coins-vertical-droppable .coin.bronze {
  background-image: url("../../assets/images/dollar_coin_bronze_v.png");
  background-size: contain;
  background-repeat: no-repeat;
}

img.avatar-pic {
  width: 100%;
  display: flex;
}
img.pin-pic {
  display: flex;
  margin: -6px 2px;
  width: 20px;
}

.coins-sum {
  position: absolute;
  right: 3px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  top: 0;
  text-align: center;
  animation: show 300ms ease-in;
  animation-fill-mode: forwards;
  z-index: 3;
  -webkit-text-stroke: 0.1px white;
  background: #335a97;
}

@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
