.field-properties-popup .Token-droppable-field {
  width: 50% !important;
  margin: 0 auto !important;
  height: 80px !important;
}
.field-properties-popup .Token-droppable-field .Token-text {
  font-size: 12px;
  line-height: 2em;
}
.field-properties-popup .Token-droppable-field .Token-value {
  font-size: 20px;
  line-height: 2em;
}

.field-properties-popup .Token-container.black .Token-text {
  font-size: 10px !important;
}
.field-properties-popup .Token-container.black .Token-value {
  font-size: 20px !important;
  line-height: 2em;
}

.ContainerPropertiesPopup .modal-content {
  padding: 20px;
  width: 400px;
}
.ContainerPropertiesPopup table {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}

.containerModalObjClass {
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
}
.containerModalObjClass .objModelClass {
  width: 150px;
  height: 80px;
  margin-right: auto;
}
.containerModalObjClass .coins-container {
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 2;
  display: flex;
}
.containerModalObjClass .coins {
  width: 10px !important;
  height: 42px !important;
  /*position: absolute;
    width: 30px!important;
    height: 60px!important;
    top: 9px;*/
}

.coins-container .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.containerModalObjClass .coin {
  width: 12px !important;
  height: 60px !important;
  vertical-align: middle;
  margin: auto 0;
}
.containerModalObjClass .coin.gold {
  background-image: url("../images/dollar_coin_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.containerModalObjClass .coin.silver {
  background-image: url("../images/dollar_coin_silver_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.containerModalObjClass .coin.bronze {
  background-image: url("../images/dollar_coin_bronze_h.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.containerModalObjClass .coin:nth-child(2) {
  margin-left: 0px;
}
.containerModalObjClass .coin:nth-child(3) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(4) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(5) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(6) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(7) {
  margin-left: 0px;
}
.containerModalObjClass .coin:nth-child(8) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(9) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(10) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(11) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(12) {
  margin-left: -1px;
}
.containerModalObjClass .coin:nth-child(13) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(14) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(15) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(16) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(17) {
  margin-left: -2px;
}
.containerModalObjClass .coin:nth-child(18) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(19) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(20) {
  margin-left: -7px;
}
.containerModalObjClass .coin:nth-child(21) {
  margin-left: -7px;
}

.coins-container .Container-Card {
  /*position: absolute;
    width: 12px!important;
    height: 40px!important;
    transform: rotateY(57deg) rotateZ(-90deg);
    right: 1px;
    top: 5px;*/
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  right: -11px;
  transform: rotateY(72deg) rotateZ(0deg);
  top: 50%;
  margin-top: -20px;
}

.card-droppable .Container-Card {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  transform: rotateZ(-90deg);
  right: 5px;
  top: 5px;
}

.coins-container .productCardContainer {
  /*position: absolute;
    width: 60px!important;
    height: 60px!important;
    transform: rotateY(60deg) rotateZ(-90deg);
    right: -4px;
    top: 10px;*/
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  transform: rotateY(70deg) rotateZ(-90deg);
  right: -10px;
  top: 5px;
}
.coins-container .productCardImg {
  width: 100%;
  height: 100%;
}
.coins-container .productCardContainer .productCardValue {
  font-size: 22px;
  text-align: center;
  top: 45%;
  position: absolute;
  width: 100%;
  height: 24px;
  margin-top: -12px;
  font-weight: bolder;
  color: black;
}

/*tr:hover {
    color: white!important;
}*/

.purchase-btn {
  color: #ffffff;
  background-color: #002955 !important;
  border: 1px solid #002955 !important;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  font-weight: 600;
}
.purchase-btn:hover,
.purchase-btn:active,
.purchase-btn:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rent-btn {
  color: #002955 !important;
  background-color: #ffffff !important;
  border: 1px solid #002955 !important;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  font-weight: 600;
}
.rent-btn:hover,
.rent-btn:active,
.rent-btn:focus {
  color: #002955;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.questions-container {
  margin-top: 10px;
}
.question-btn {
  margin-top: 5px;
}
.question-input input {
  width: 100%;
}

.liabilityObjClass {
  position: relative;
  display: flex;
  width: 150px;
  height: 80px;
  margin: auto;
}
.liabilityObjClass .tokenDraggable {
  width: 100% !important;
  height: 100% !important;
}
.liabilityObjClass .Token-container-draggable {
  width: 100% !important;
  height: 100% !important;
}
.liabilityObjClass .Token-text {
  font-size: 15px !important;
}
.liabilityObjClass .Token-value {
  font-size: 25px !important;
}
