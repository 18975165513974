.region-block {
  width: 620px;
  background-color: white !important;
  padding: 10px !important;
  color: black !important;
  display: flex !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  border-radius: 0 !important;
  align-items: center;
}

.service-block {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin: 10px auto;
  margin-left: 0;
  height: 100%;
  padding: 5px;
  padding-top: 0;
}
.service-block > div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.service-block > div > div:nth-of-type(1) {
  position: absolute;
  z-index: 2;
  background: white;
  top: -14px;
  width: 80px;
  text-align: center;
  left: 5px;
}
.service-block > div > div:nth-of-type(2) {
  display: flex;
  column-gap: 10px;
  padding: 10px;
}
.service-block:nth-of-type(1) > div > div:nth-of-type(2) > .service-cell {
  background-color: #ffda00;
}

.service-block:nth-of-type(2) > div > div:nth-of-type(2) > .service-cell {
  background-color: #23913c;
}
.service-block:nth-of-type(1)
  > div
  > div:nth-of-type(2)
  > .service-cell:nth-of-type(3)
  > svg
  > g
  > g
  > .cls-2 {
  fill: #ffda00;
  stroke: #ffda00;
  clip-path: inherit;
}
.service-block:nth-of-type(2)
  > div
  > div:nth-of-type(2)
  > .service-cell:nth-of-type(3)
  > svg
  > g
  > g
  > .cls-2 {
  fill: #23913c;
  stroke: #23913c;
  clip-path: inherit;
}
.service-block:nth-of-type(3) > div > div:nth-of-type(2) > .service-cell {
  background-color: #00acd4;
}
.service-block:nth-of-type(3)
  > div
  > div:nth-of-type(2)
  > .service-cell:nth-of-type(3)
  > svg
  > g
  > g
  > .cls-2 {
  fill: #00acd4;
  stroke: #00acd4;
  clip-path: inherit;
}
.service-cell {
  width: 40px;
  height: 80px;
  padding: 5px;
  position: relative;
  display: flex;
}
.service-cell > span {
  position: absolute;
  bottom: -12px !important;
  left: 0;
  font-size: 7px !important;
  text-transform: initial;
}
.emoji {
  position: absolute;
  width: 17px;
  right: -4px;
  top: -5px;
}
.o-7 {
  opacity: 0.7;
}

.employee-container {
  display: flex;
  margin-top: 8px;
}
.employee-container > div {
  margin: 10px auto;
  margin-top: 12px;
  display: flex;
}
.employee-container > div:nth-of-type(1) {
  margin-left: 0;
  margin-right: 0;
}
.employee-container > div:nth-of-type(1) > div {
  border-color: #ffda00;
}

.employee-container > div:nth-of-type(2) > div {
  border-color: #23913c;
}
.employee-container > div:nth-of-type(3) > div {
  border-color: #00acd4;
}

.employee-container > div:nth-of-type(1) > div > div > .employee-circle {
  background-color: #ffda00;
}
.employee-container > div > div > div:nth-of-type(1) > .employee-circle {
  background-color: grey !important;
}
.employee-container > div:nth-of-type(2) > div > div > .employee-circle {
  background-color: #23913c;
}
.employee-container > div:nth-of-type(3) > div > div > .employee-circle {
  background-color: #00acd4;
}
.employee-container > div:nth-of-type(3) {
  margin-left: 0;
}
.employee-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: center;
  border-top: 2px dotted;
}
.employee-block > div {
  display: flex;
  column-gap: 15px;
}
.employee-block > div:nth-of-type(1) {
  margin-top: -22px;
}

.employee-circle {
  width: 20px;
  height: 20px;
  border-radius: 49%;
}

.cls-1 {
  stroke: inherit !important;
}
.region-block > .accordion-header > .accordion-button {
  padding: 7px 20px !important;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-item {
  border: 0 !important;
}

.accordion-button,
.accordion-body {
  padding: 7px 10px !important;
}

.accordion-button {
  color: black !important;
  text-transform: uppercase;
}
