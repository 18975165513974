.formContainer {
  background-color: #0a2955;
  padding: 10px;
}
.formContainer .removeLeftPadding {
  padding-left: 0px !important;
}

.formContainer .bold {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
.formContainer .formHeader {
  display: flex;
  padding: 10px;
}
.formContainer .headerTitle {
  color: #ffffff;
  font-size: 18px;
  flex: 2;
}
.formContainer .closeForm {
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  flex: 1;
  text-align: right;
}
.formContainer td {
  padding: 5px !important;
}
.formContainer .btn {
  font-size: 10px !important;
}
.checkContainer {
  display: flex;
  width: 70px;
  height: 25px;
  border: 1px solid lightgray;
  border-radius: 2px;
  cursor: pointer;
}
.checkContainer svg {
  color: #ffffff;
  margin: auto;
}

.hintForm {
  font-size: 10px;
  font-style: italic;
  color: lightgray;
}

.form-link div {
  cursor: pointer;
  color: #002955;
  font-size: 14px;
}
.form-link div:hover {
  text-decoration: underline;
}

.whiteDropdown button {
  color: #ffffff;
  border-color: #ffffff;
}

.autofillFieldBackground {
  background-color: #6076ab;
}
.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem !important;
  font-size: inherit !important;
  border-radius: 0.2rem;
}

.subtext {
  color: white;
  font-size: 9px;
  text-align: center;
}
.liquidity > div:nth-child(2) > input {
  width: 30px;
  height: 25px;
  text-align: center;
  border-radius: 3px;
  border-color: transparent;
}
.liquidity > div:nth-child(2) {
  text-align: center;
  padding: 5px;
}

.form-check-input {
  margin-top: 0 !important;
}

.td-liquidity {
  vertical-align: bottom;
}
.plus-minus {
  position: absolute;
  left: -63px;
  top: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 70px;
  align-items: center;
  justify-content: space-around;
}


.largeField input{
  width: 65px !important;
}