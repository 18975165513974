.left-block {
  width: 290px;
  background-color: white;
  padding: 5px 0;
  position: relative;
  z-index: 10;
}
.left-block > * {
  padding: 0 20px 25px;
  border-bottom: 1px solid #203b52;
}
.left-block > div:nth-of-type(1) {
  border-bottom: 1px solid #203b52;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.left-block-grid {
  display: grid;
  grid-template-columns: 40px 40px 40px;
  column-gap: 7px;
  row-gap: 7px;
  grid-auto-rows: 80px;
}
.block-container > svg {
  margin: auto;
}

.equity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;
  margin-top: auto;
}
.equity-container > div {
  width: 80px;
  height: 40px;
}

.w-h {
  width: 80px;
  height: 40px;
  margin-top: 20px;
}

.block-container > svg > g > g > .cls-3,
.block-container > svg > g > g > .cls-4 {
  stroke: white;
}

.gears-block-grid {
  display: grid;
  grid-template-columns: 80px 80px;
  justify-content: space-between;
  grid-auto-rows: 40px;
}

.rectangle {
  width: 80px;
  height: 40px;
}

.left-service-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 5px;
}
.left-block > div:nth-of-type(4) > div:nth-of-type(1) {
  background-color: #ffda00;
}
.left-block > div:nth-of-type(4) > div:nth-of-type(2) {
  background-color: #23913c;
}
.left-block > div:nth-of-type(4) > div:nth-of-type(3) {
  background-color: #00acd4;
}
.left-service-bar > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 10px;
  padding-right: 10px;
}
.left-service-bar > div:nth-of-type(2) > div,
.left-service-bar > div:nth-of-type(3) {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.left-service-bar > div:nth-of-type(3) {
  opacity: 0.7;
}
.o-1 {
  opacity: 1 !important;
}

.left-service-bar > div:nth-of-type(2) {
  display: flex;
  margin-left: -15px;
  margin-top: auto;
  margin-bottom: 8px;
}
.last-left-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 117px;
  z-index: 10;
  position: relative;
}
.last-left-bar > div {
  width: 140px;
  height: 160px;
  background-color: white;
  padding: 5px;
  text-align: center;
}
.last-left-bar > div > div > span {
  font-size: 12px;
}
.last-left-bar > div > div:nth-of-type(1) > div {
  display: flex;
  justify-content: end;
  border-bottom: 1px solid #203b52;
  padding-bottom: 5px;
  align-items: center;
  position: relative;
}
.last-left-bar > div > div:nth-of-type(1) > div > div:nth-of-type(1) {
  background-color: rgb(192, 198, 202);
  margin-left: 10px;
  position: absolute;
  z-index: 10;
  right: 4px;
}
.last-left-bar > div > div:nth-of-type(1) > div > div:nth-of-type(2) {
  background-color: rgb(192, 198, 202);
  margin-left: 10px;
  position: absolute;
  z-index: 10;
  right: 42px;
}
.centralSvg > svg,
.lowSvg > svg {
  width: 28px;
  height: 28px;
}

.centralSvg > svg:nth-child(2),
.lowSvg > svg:nth-child(2) {
  margin-left: 10px;
}

.centralSvg > svg > g > g > * {
  fill: #a99370;
}
.lowSvg > svg > g > g > * {
  fill: #910028;
}

.last-left-bar > div > div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.last-left-bar > div > div:nth-of-type(2) > div > svg > g > g > * {
  fill: white;
}

.last-left-bar > div:nth-of-type(1) > div:nth-of-type(2) > div {
  background-color: #cec1ac;
  justify-content: center;
  align-items: center;
}
.last-left-bar > div > div:nth-of-type(2) > div {
  background-color: #c47976;
  align-items: center;
  justify-content: center;
}

.left-hub-link {
  position: absolute;
  width: 70px;
  left: 140px;
  top: -130px;
  z-index: 2;
}
.left-hub-link > svg > g > g > path {
  fill: none !important;
  stroke: #a99370 !important;
}
.right-hub-link {
  position: absolute;
  width: 70px;
  left: 220px;
  top: -120px;
  bottom: 0;
  z-index: 2;
}
.right-hub-link > svg > g > g > path {
  fill: none !important;
  stroke: #910028 !important;
}
