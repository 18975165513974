.right-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.button-header {
  background-color: white !important;
  padding: 13px !important;
  color: black !important;
  display: flex !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  border-radius: 0 !important;
  width: 290px;
  margin: 1px 22px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.right-block > div {
  width: 290px;
  display: flex;
  background: white;
  padding: 6px 11px;
  column-gap: 7px;
  padding-bottom: 17px;
  justify-content: space-around;
  flex-direction: column;
  margin: 0px 22px;
  margin-bottom: 0;
}
.right-block > div > span {
  font-size: 14.5px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.right-block > div:nth-of-type(1) {
  height: 75px;
}
.right-block > div:nth-of-type(1) > div:nth-of-type(2) {
  width: 33.3%;
}
.right-block > div:nth-of-type(1) > div:nth-of-type(1) {
  width: 60%;
}
.right-block > div:nth-of-type(1) > div:nth-of-type(1)::after {
  height: 75px;
  content: "";
  width: 1px;
  border-right: 1px solid black;
  position: absolute;
  top: -7px;
  right: -7px;
}

.block-container {
  background-color: #c0c6ca;
  padding: 7px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
.block-container > svg {
  width: 100%;
}
.block-container > span {
  position: absolute;
  bottom: -15px;
  left: 0;
  font-size: 8px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 7px;
  row-gap: 17px;
  grid-auto-rows: 40px;
}

.motivation > svg > g > g > .cls-2 {
  fill: white !important;
  clip-path: inherit;
}
.block-container > svg > g > g > .cls-2,
.block-container > svg > g > g > .cls-3,
.block-container > svg > g > g > .cls-4 {
  fill: #c0c6ca;
}

.block-container > svg > g > g > .cls-5,
.block-container > svg > g > g > .cls-6 {
  fill: white;
  stroke-width: 0;
}
.block-container > svg > g > g > .cls-2 {
  stroke: #c0c6ca;
}
.money-svg > svg > g > g > .cls-2 {
  stroke: #c0c6ca;
  clip-path: inherit;
}
.pig > svg > g > g > .cls-2,
.pig > svg > g > g > .cls-3,
.pig > svg > g > g > .cls-4 {
  fill: white;
  clip-path: inherit;
}

.pig > svg > g > g > .cls-4 {
  fill: black;
}

.w-100 > div > input {
  width: 100%;
  text-align: center;
}


.svg-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-container svg {
  width: 30px;
}